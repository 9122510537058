// ==================================================================
// 
// Flexible Benefits List
// 
// ==================================================================

@import "assets/scss/utilities/mixins";
@import "assets/scss/utilities/variables";
@import "../../../node_modules/foundation-sites/scss/util/_unit.scss";
@import "../../../node_modules/foundation-sites/scss/util/value.scss";
@import "../../../node_modules/foundation-sites/scss/util/_breakpoint.scss";

.flexible-benefits-list {

}

.benefits {
    position: relative;  

    &::before {
      content: '';
      display: block;
      z-index: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0 0 0 0;
      background-color: var(--black);

      @include breakpoint(medium) {
          height: 88.3%;
      }

    }

    & > * {
      position: relative;
      z-index: 1;
    }

    // Default padding and margin if not set under 'Settings'.
    &.padding-default {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    } 
    
    &.small-padding-default {
        @include breakpoint( small only ) {
            padding-bottom: 0;
        }
    }  
    
    &.margin-default {
        margin-top: 0;
        margin-bottom: 0;
    } 
    
    &.small-margin-default {
        @include breakpoint( small only ) {
            margin-bottom: rem-calc(40);
        }
    }  

    &__intro {
        position: relative;
    }

    &__intro-header {
        font-family: $Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: rem-calc(48px);
        line-height: (59/48);
        text-align: center;
        color: #FFFFFF;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);

        @include breakpoint( small only ) {
            font-size: rem-calc(32px); 
        }

        @at-root {
            .single.single-solution #{&} {
                text-align: left;
            }
        }
    }

    &__intro-text {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: rem-calc(20px);
        line-height: rem-calc(28px);
        text-align: center;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);
        color: #889EEE;
        
        p {
            font-family: $Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: rem-calc(20px);
            line-height: rem-calc(28px);
            text-align: center;
            text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);
            color: #889EEE;
        }

        @include increased-contrast {
            color: white;
        }

        @at-root {
            .single.single-solution #{&} p {
                font-family: $Roboto;
                font-style: normal;
                font-weight: 400;
                font-size: rem-calc(20px);
                line-height: (28/20);
                color: #889EEE; 
                text-align: left !important;

                &:last-child {
                    @include breakpoint(xlarge) {
                        margin-bottom: 0.5rem;
                    }
                }

                @include increased-contrast {
                    color: #FFFFFF;
                }
            }
        }
    }

    .benefits__list-item {
        &:nth-child(odd) .bfg-block {
            @include breakpoint(xlarge) {
                transform: translateX(15px)
            }
        }
        &:nth-child(even) .bfg-block {
            @include breakpoint(xlarge) {
                transform: translateX(-15px)
            }
        }
        
        .bfg-block {
            @include breakpoint(xlarge) {
                max-width: rem-calc(420);
                flex: 0 1 rem-calc(420);
                margin: auto
            }
        }

        // Services ('Solutions') Singles
        &.xlarge-4 {

            .bfg-block {
                transform: translate(0,0);
            }
        }
    }

    &__cta {
        @include breakpoint( small only ) {
            padding-left: 0;   
            padding-right: 0;   
        }

        .grid-x .cell .position-relative.above-overlay {
            @include breakpoint( small only ) {
                padding-left: 1.5rem;   
                padding-right: 1.5rem;   
            }
        }

        h3 {
            font-family: $Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: rem-calc(28px);
            line-height: rem-calc(34px);
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 1.25rem;
            text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);
        }

        &-text {
            p {
                color: white;
                line-height: 1.5;
                text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);
            }
            
            p:last-child {
                margin-top: 1.5rem;
            }

            .button {
                font-family: $Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: rem-calc(15px);
                line-height: rem-calc(30px);
                text-transform: uppercase;
                color: #FFFFFF;

                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);
            }

        }

    }

}

// Basic Layout

.benefits.layout-basic {
    $this: '.benefits';

    .benefits__list-item.cell[class]  {
        @include breakpoint(medium) {
            flex: 0 0 auto;
            width: 41.66667%;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        &:last-child:nth-child(odd) {
            width: 83.4%;
            margin-bottom: 2rem;

            @include breakpoint(medium) {
                margin-bottom: 3rem;
            }

            p {
                @include breakpoint(medium) {
                    margin-bottom: 0.5rem;
                    padding-right: 2.5rem;
                }
            }
        }
        
        .bfg-block {
            background-color: var(--dark-gray);

            @include breakpoint(medium) {
                transform: translateX(0) !important;
                width: 100%;
                max-width: unset;
                flex: 1 0 auto;
                margin: 0;
                display: flex;
                gap: 30px;
            }

            &__image {
                @include breakpoint(medium) {
                    position: relative;
                    inset: auto;
                    transform: none;
                    background-color: var(--dark-gray);
                    padding-top: rem-calc(32px);
                    padding-bottom: rem-calc(32px);
                    display: flex;
                    align-items: center;
                }

                .bfg-icon {
                    @include breakpoint(medium) {
                        transform: translate(2rem,0);
                    }
                }
            }

            &__body {
                clip-path: none;
                padding-top: rem-calc(24px);
                padding-bottom: rem-calc(32px);
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;

                h3 {
                    text-transform: none;
                }

                p {
                    margin-bottom: 0;
                }
            }

            &__header,
            &__text * {
                text-align: left;
            }
        }
    }
}