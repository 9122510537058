@mixin nv-page-loaded {
    @at-root {
        .nv-js-page-is-loaded #{&} {
            @content;
        }
    }
}

@mixin reduced-motion {
    @media (prefers-reduced-motion: reduce) {
        @content;
    }
}

@mixin increased-contrast {
    @media screen and (prefers-contrast: more) {
        @content;
    }
}

// Force Full Width when and element is inside a container width a defined width
@mixin force-full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw !important;
  margin-right: -50vw !important;
}

// IE - Enable _ie.js
@mixin is-ie() {
    @at-root {
        .is-ie {
            @content;
        }
    }
}

// Edge - Enable _ie.js
@mixin is-edge() {
    @at-root {
        .is-edge {
            @content;
        }
    }
}

// Mobile
@mixin is-mobile( $appendToParentClass: false ) {
    @if $appendToParentClass {
        @at-root {
            .is-mobile#{$appendToParentClass} {
                @content;
            }
        }
    }
    @else {
        @at-root {
            .is-mobile {
                @content;
            }
        }
    }
}

// Tablet
@mixin is-tablet( $appendToParentClass: false ) {
    @at-root {
        @if $appendToParentClass {
            .is-tablet#{$appendToParentClass} {
                @content;
            }
        } 
        @else {
            .is-tablet {
                @content;
            }
        }
    }
}

// Letter Spacing
@mixin letter-spacing($spacing: 30px) {
    letter-spacing: rem-calc($spacing);
    margin-right: rem-calc(-$spacing);
}

// Header
@mixin headers( $sizes: (1, 2, 3, 4, 5, 6) ) {
    @each $i in $sizes {
        h#{$i},
        .h#{$i} {
            @content;
        }
    }
}

// Fade up on scroll
@mixin fade-up-on-scroll( $offset: 25, $delay: 0.15s ) {
    opacity: 0;
    transform: translateY(rem-calc($offset));
    will-change: transform;
    transition-delay: $delay, $delay;
    transition-property: opacity, transform;
    transition-duration: 1.2s, 1.4s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1),cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

// Placeholder
@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin placeholder {
    @include optional-at-root("::-webkit-input-placeholder") {
        @content;
    }

    @include optional-at-root(":-moz-placeholder") {
        @content;
    }

    @include optional-at-root("::-moz-placeholder") {
        @content;
    }

    @include optional-at-root(":-ms-input-placeholder") {
        @content;
    }
}

// Reset our ul/ol lists
// Use no-bullet
@mixin reset-list {
    list-style: none;
    margin-left: 0;
    margin-bottom: 0;
}

// Center content using Flexbox
@mixin flex-center($justify: center, $align: center) {
    align-items: $align;
    display: flex;
    justify-content: $justify;
}

// Describe all psuedo state classes
@mixin hover {
    &:focus,
    &:hover {
        @content;
    }
}

// Manual Absolute
@mixin absolute($top: null, $right: null, $bottom: null, $left: null) {
    position: absolute;
    @if ($top) {
        top: $top;
    }
    @if ($right) {
        right: $right;
    }
    @if ($bottom) {
        bottom: $bottom;
    }
    @if ($left) {
        left: $left;
    }
}

// z-index management
$layers: (
    overlay: 10,
    above-overlay: 20,
    header: 30,
    nav: 40,
);

@mixin z-index($layer-name) {
    z-index: map-get($layers, $layer-name);
}

// Transition
// - duration is the first arg since it is the most frequently altered option
@mixin transition($duration: 0.35s, $property: all, $timing: ease-in-out) {
    transition: $property $duration $timing;
}

// Spacing helper for small amounts of padding or margin

@mixin small-spacing(
    $spacing-type: "padding",
    $spacing-location: "topbottom",
    $pixel-base: 15
) {
    @if $spacing-location == "topbottom" {
        &--tiny {
            #{$spacing-type}-top: rem-calc($pixel-base - 10);
            #{$spacing-type}-bottom: rem-calc($pixel-base - 10);
        }

        &--xsmall {
            #{$spacing-type}-top: rem-calc($pixel-base);
            #{$spacing-type}-bottom: rem-calc($pixel-base);
        }
    }
    @elseif $spacing-location == 'top' {
        &--tiny {
            #{$spacing-type}-top: rem-calc($pixel-base - 10);
        }

        &--xsmall {
            #{$spacing-type}-top: rem-calc($pixel-base);
        }
    }
    @elseif $spacing-location == 'bottom' {
        &--tiny {
            #{$spacing-type}-bottom: rem-calc($pixel-base - 10);
        }

        &--xsmall {
            #{$spacing-type}-bottom: rem-calc($pixel-base);
        }
    }
}

// Padding helper

@mixin padding($amount: "default", $applyto: "both") {
    // Reduced amount of padding
    @if ($amount == "reduced" or $amount == "small") {
        // Top Padding
        @if ($applyto == "both" or $applyto == "top") {
            padding-top: rem-calc(20);

            @include breakpoint(medium) {
                padding-top: rem-calc(25);
            }

            @include breakpoint(large) {
                padding-top: rem-calc(30);
            }
        }

        // Bottom Padding
        @if ($applyto == "both" or $applyto == "bottom") {
            padding-bottom: rem-calc(20);

            @include breakpoint(medium) {
                padding-bottom: rem-calc(25);
            }

            @include breakpoint(large) {
                padding-bottom: rem-calc(30);
            }
        }

        // Default amount of padding
    } @else if ($amount == "default" or $amount == "medium") {
        // Top Padding
        @if ($applyto == "both" or $applyto == "top") {
            padding-top: rem-calc(30);

            @include breakpoint(medium) {
                padding-top: rem-calc(45);
            }

            @include breakpoint(large) {
                padding-top: rem-calc(60);
            }
        }

        // Bottom Padding
        @if ($applyto == "both" or $applyto == "bottom") {
            padding-bottom: rem-calc(30);

            @include breakpoint(medium) {
                padding-bottom: rem-calc(45);
            }

            @include breakpoint(large) {
                padding-bottom: rem-calc(60);
            }
        }

        // Increased amount of padding
    } @else if ($amount == "increased" or $amount == "large") {
        // Top Padding
        @if ($applyto == "both" or $applyto == "top") {
            padding-top: rem-calc(40);

            @include breakpoint(medium) {
                padding-top: rem-calc(60);
            }

            @include breakpoint(large) {
                padding-top: rem-calc(75);
            }
        }

        // Bottom Padding
        @if ($applyto == "both" or $applyto == "bottom") {
            padding-bottom: rem-calc(40);

            @include breakpoint(medium) {
                padding-bottom: rem-calc(60);
            }

            @include breakpoint(large) {
                padding-bottom: rem-calc(75);
            }
        }

        // Extreme amount of padding
    } @else if ($amount == "extreme" or $amount == "xlarge") {
        // Top Padding
        @if ($applyto == "both" or $applyto == "top") {
            padding-top: rem-calc(120);

            @include breakpoint(medium) {
                padding-top: rem-calc(150);
            }

            @include breakpoint(large) {
                padding-top: rem-calc(175);
            }

            @include breakpoint(xxlarge) {
                padding-top: rem-calc(200);
            }
        }

        // Bottom Padding
        @if ($applyto == "both" or $applyto == "bottom") {
            padding-bottom: rem-calc(120);

            @include breakpoint(medium) {
                padding-bottom: rem-calc(150);
            }

            @include breakpoint(large) {
                padding-bottom: rem-calc(175);
            }

            @include breakpoint(xxlarge) {
                padding-bottom: rem-calc(200);
            }
        }
    }
}

// Margin helper
@mixin margin($amount: "default", $applyto: "both") {
    // Reduced amount of margin
    @if ($amount == "reduced" or $amount == "small") {
        // Top margin
        @if ($applyto == "both" or $applyto == "top") {
            margin-top: rem-calc(20);

            @include breakpoint(medium) {
                margin-top: rem-calc(25);
            }

            @include breakpoint(large) {
                margin-top: rem-calc(30);
            }
        }

        // Bottom margin
        @if ($applyto == "both" or $applyto == "bottom") {
            margin-bottom: rem-calc(20);

            @include breakpoint(medium) {
                margin-bottom: rem-calc(25);
            }

            @include breakpoint(large) {
                margin-bottom: rem-calc(30);
            }
        }

        // Default amount of margin
    } @else if ($amount == "default" or $amount == "medium") {
        // Top margin
        @if ($applyto == "both" or $applyto == "top") {
            margin-top: rem-calc(30);

            @include breakpoint(medium) {
                margin-top: rem-calc(45);
            }

            @include breakpoint(large) {
                margin-top: rem-calc(60);
            }
        }

        // Bottom margin
        @if ($applyto == "both" or $applyto == "bottom") {
            margin-bottom: rem-calc(30);

            @include breakpoint(medium) {
                margin-bottom: rem-calc(45);
            }

            @include breakpoint(large) {
                margin-bottom: rem-calc(60);
            }
        }

        // Increased amount of margin
    } @else if ($amount == "increased" or $amount == "large") {
        // Top margin
        @if ($applyto == "both" or $applyto == "top") {
            margin-top: rem-calc(40);

            @include breakpoint(medium) {
                margin-top: rem-calc(60);
            }

            @include breakpoint(large) {
                margin-top: rem-calc(75);
            }
        }

        // Bottom margin
        @if ($applyto == "both" or $applyto == "bottom") {
            margin-bottom: rem-calc(40);

            @include breakpoint(medium) {
                margin-bottom: rem-calc(60);
            }

            @include breakpoint(large) {
                margin-bottom: rem-calc(75);
            }
        }

        // Extreme amount of margin
    } @else if ($amount == "extreme" or $amount == "xlarge") {
        // Top margin
        @if ($applyto == "both" or $applyto == "top") {
            margin-top: rem-calc(120);

            @include breakpoint(medium) {
                margin-top: rem-calc(150);
            }

            @include breakpoint(large) {
                margin-top: rem-calc(175);
            }

            @include breakpoint(xxlarge) {
                margin-top: rem-calc(200);
            }
        }

        // Bottom margin
        @if ($applyto == "both" or $applyto == "bottom") {
            margin-bottom: rem-calc(120);

            @include breakpoint(medium) {
                margin-bottom: rem-calc(150);
            }

            @include breakpoint(large) {
                margin-bottom: rem-calc(175);
            }

            @include breakpoint(xxlarge) {
                margin-bottom: rem-calc(200);
            }
        }
    }
}

/// xy-grid is missing mixin for cell shortcode
/// Creates a cell for your grid along with responsive sizes for the cell. A shorthand version of using `xy-cell` within the breakpoint mixin.
///
/// @param {Map} $sizes [null] - Accepts a map of breakpoint size : cell size key value pairs, eg (small: 12, medium: 6, large: 4).
/// @param {Boolean} $gutter-output [true] - Whether or not to output gutters
/// @param {Number|Map} $gutters [$grid-margin-gutters] - Map or single value for gutters.
/// @param {Keyword} $gutter-type [margin] - Gutter type of the cell. Accepts `margin` or `padding`.
/// @param {List} $gutter-position [right left] - The position to apply gutters to. Accepts `top`, `bottom`, `left`, `right` in any combination.
/// @param {Boolean} $vertical [false] - Set to true to output vertical (height) styles rather than widths.
@mixin xy-cell-breakpoints(
    $sizes: null,
    $gutter-output: true,
    $gutters: $grid-margin-gutters,
    $gutter-type: margin,
    $gutter-position: right left,
    $vertical: false
) {
    @each $breakpoint, $size in $sizes {
        @include breakpoint($breakpoint) {
            // Get our gutters from map if available, if not map just return the value.
            $gutter: -zf-get-bp-val($gutters, $breakpoint);

            // Base flex properties
            @include xy-cell-base($size);

            @if ($gutter-type == "margin") {
                @include -xy-cell-properties($size, $gutter, $vertical);
            } @else {
                @include -xy-cell-properties($size, 0, $vertical);
            }

            @if $gutter-output {
                // If gutters = map
                @if (type-of($gutters) == "map") {
                    // If $gutters map has a key = $breakpoint, output the value
                    @if (map-has-key($gutters, $breakpoint)) {
                        @include xy-gutters(
                            $gutter,
                            $gutter-type,
                            $gutter-position
                        );
                    }
                } @else {
                    @include xy-gutters(
                        $gutter,
                        $gutter-type,
                        $gutter-position
                    );
                }
            }
        }
    }
}

// Set transition on menu-item submenu's
@mixin submenu-transition($_submenu_fadein_only: false) {
    @if $_submenu_fadein_only == false {
        transition: opacity 0.15s ease-out, transform 0.15s ease-out;
    } @else {
        transition: opacity 0.15s ease-out;
    }
}
