/**
 * Theme Variables
 */

// Colour
$true-black: #000000;
$black: #171718;
$true-white: #ffffff;
$light-purple: #889EEE;
$dark-purple: #1939AA !global;
$purple: #5259EC !global;
$darkgray: #424142;
$lightgray: #E9E9E9;
$purple-gradient: linear-gradient(139.02deg, #5259EC 15.47%, #18359E 84.32%);
$drop-shadow: 0 4px 56px 0 #1939aa26;
$large-drop-shadow: 0 4px 40px 0 #1939aa80;

:root {
    --light-purple: #{$light-purple};
    --dark-purple: #{$dark-purple};
    --purple: #{$purple};
    --white: #{$true-white};
    --black: #{$black};
    --dark-gray: #{$darkgray};
    --light-gray: #{$lightgray};

    --primary-color: #{$purple};

    --purple-gradient: #{$purple-gradient};
    --drop-shadow: #{$drop-shadow};
}

// Font Family
$mont: 'Montserrat', sans-serif !global;
$Montserrat: 'Montserrat', sans-serif !global;
$roboto: 'Roboto', sans-serif !global;
$Roboto: 'Roboto', sans-serif !global;
$fa: Font Awesome\ 5 Pro !global;
$far: Font Awesome\ 5 Regular !global;
$fas: Font Awesome\ 5 Solid !global;
$fal: Font Awesome\ 5 Light !global;
$fab: Font Awesome\ 5 Brands!global;

:root {
    --mont: #{$mont};
    --roboto: #{$roboto};
}

// Font Weight
$weight-extralight: 200;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;
$weight-black: 700;
$global-weight-light: 300;
$global-weight-regular: 400;
$global-weight-medium: 500;
$global-weight-semibold: 600;
$global-weight-bold: 700;

// Misc
$grid-gutter: 1rem;